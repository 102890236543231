









































import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { TextDecimal, FscSimpleCard },
})
export default class OverviewCard extends Vue {
  public name = "OverviewCard";

  @Prop()
  protected total!: any;

  @Prop()
  protected label!: any;

  @Prop()
  protected labelClass!: any;

  @Prop()
  protected classicPayAmount!: any;

  @Prop()
  protected livePayAmount!: any;

  @Prop()
  protected flashPayAmount!: any;
}
